import React, { useEffect } from 'react';
import { getNestedFolders, isTokenValid } from '../driveApi';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { rootFolderIdState } from '../state';

const Login: React.FC<{ accessToken: string }> = ({ accessToken }) => {
  const navigate = useNavigate();
  const [, setRootFolderId] = useRecoilState(rootFolderIdState);
  useEffect(() => {
    const checkIfTokenIsValid = async () => {
      const isValid = await isTokenValid(accessToken);
      if (isValid) {
        const rootFolderPath = ["Personal", "Obsidian", "NotesSync"];
        const rootFolderId = await getNestedFolders(rootFolderPath);
        setRootFolderId(rootFolderId);
        navigate('/files');
      }
    };
    checkIfTokenIsValid();
  }, [accessToken, navigate, setRootFolderId]);

  const clientId = '159719211193-ig91rh4v7ggconpopjkaed7gjbiqsqua.apps.googleusercontent.com';
  const redirectUri = window.location.origin;
  const scopes = [
    'https://www.googleapis.com/auth/drive.readonly',
    'https://www.googleapis.com/auth/drive.metadata.readonly',
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/drive',
    'https://www.googleapis.com/auth/drive.appdata',
  ];

  const baseUrl = 'https://accounts.google.com/o/oauth2/auth';

  const params = [
    `response_type=token`,
    `client_id=${clientId}`,
    `redirect_uri=${redirectUri}`,
    `scope=${scopes.join(' ')}`,
  ];

  const url = `${baseUrl}?${params.join('&')}`;
  // convert the characters of the url
  // to be displayed correctly in the browser
  const encodedUrl = encodeURI(url);

  return (
    <div>
      <h1>Login</h1>
      <a href={encodedUrl}>Login with Google</a>
    </div>
  );
}

export default Login;
