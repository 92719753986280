import React, { useEffect, useState } from 'react';
import { formatName, getFilesInFolder, searchFiles, searchMarkdownFilesByContent, searchMarkdownFilesByName } from '../driveApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { rootFolderIdState } from '../state';
import SearchBox from './SearchBox';

const FolderMimeType = 'application/vnd.google-apps.folder';

const DriveFileList: React.FC = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken') ?? '';
  const [rootFolderId] = useRecoilState(rootFolderIdState);
  const folderId = useParams<{ folderId: string }>().folderId || rootFolderId;
  const [files, setFiles] = useState<any[]>([]);
  const [recentFiles, setRecentFiles] = useState<any[]>([]);
  const [searchResultFiles, setSearchResultFiles] = useState<any[]>([]);


  if (!accessToken || !rootFolderId) {
    navigate(`/`);
  }

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const filesInTheFolder = await getFilesInFolder(folderId);
        setFiles(filesInTheFolder);
      } catch (error) {
        console.error(error);
        navigate('/');
      }
    };
    const loadRecentFiles = async () => {
      try {
        const recentFiles = await searchMarkdownFilesByName('', 30);
        setRecentFiles(recentFiles);
      } catch (error) {
        console.error(error);
        navigate('/');
      }
    }

    loadFiles();
    loadRecentFiles();
  }, [folderId, navigate]);

  const getMimeString = (mimeType: String): String => {
    return mimeType === FolderMimeType ? '[]' : 'f';
  }

  const handleItemClick = async (file: any) => {
    if (file.mimeType === FolderMimeType) {
      navigate(`/folder/${file.id}`);
    } else {
      navigate(`/files/${file.id}`);
    }
  }

  const onSearchBoxEnterPressed = async (searchValue: string) => {
    const searchResults = await searchMarkdownFilesByContent(searchValue, 20);
    console.log(`Search results: ${searchResults.map((result: any) => result.name)}`);
    setSearchResultFiles(searchResults);
  }

  return (
    <div>
      <h1>Google Drive Files</h1>
      <SearchBox onEnterPressed={onSearchBoxEnterPressed}/>
      <button onClick={() => {
        localStorage.removeItem('accessToken');
        window.location.href = '/';
      }}>Clear Token</button>
      <h2>Files in root folder</h2>
      <ul>
        {files.map((file) => (
          <li key={file.id}>
            <a href="#" onClick={() => handleItemClick(file)}>
              {file.name} {getMimeString(file.mimeType)}
            </a>
          </li>
        ))}
      </ul>
      <h2>Search result</h2>
      <ul>
        {searchResultFiles.map((file) => (
          <li key={file.id}>
            <a href="#" onClick={() => handleItemClick(file)}>
              {file.name} {getMimeString(file.mimeType)}
            </a>
          </li>
        ))}
      </ul>
      <h2>Recent Files</h2>
      <ul>
        {recentFiles.map((file) => (
          <li key={file.id}>
            <a href="#" onClick={() => handleItemClick(file)}>
              {formatName(file.name)} {getMimeString(file.mimeType)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DriveFileList;
