// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import DriveFileList from './components/DriveFileList';
import FileDetails from './components/FileDetails';
import { RecoilRoot } from 'recoil';

const App: React.FC = () => {
  const hash = window.location.hash.substring(1); // Remove the leading `#`
  const params = new URLSearchParams(hash);
  const paramsObject = {} as any;
  params.forEach((value, key) => {
    paramsObject[key] = value;
  });

  console.log('paramsObject:', JSON.stringify(paramsObject));
  const accessToken = localStorage.getItem('accessToken') ?? paramsObject['access_token'];

  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }

  return (
    <RecoilRoot>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Login accessToken={accessToken} />} />
            <Route path="/files" element={<DriveFileList />} />
            <Route path="/files/:fileId" element={<FileDetails />} />
            <Route path="/folder/:folderId" element={<DriveFileList />} />
          </Routes>
        </div>
      </Router>
    </RecoilRoot>
  );
};

export default App;
