import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import { formatName, searchMarkdownFilesByName } from '../driveApi';
import { useNavigate } from 'react-router-dom';

// SearchBox component props, that includes a callback function to handle an an pressed key
interface SearchBoxProps {
    onEnterPressed: (searchContent: string) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ onEnterPressed }) => {
    const navigate = useNavigate();
    const [options, setOptions] = React.useState<readonly string[]>([]);
    return <Autocomplete
        id="googledrive"
        sx={{ width: 300 }} autoComplete
        options={options}

        onChange={(event: any, newValue: any | null) => {
            console.log(newValue);
            navigate(`/files/${newValue.id}`);
        }} 
        onInputChange={(event, newInputValue) => {
            console.log(newInputValue);
            const debouncedSearch = debounce(async () => {
                const searchResults = await searchMarkdownFilesByName(newInputValue);
                console.log(searchResults);
                setOptions(searchResults.map((result: any) => ({ label: formatName(result.name), id: result.id })));
            }, 400);
            debouncedSearch();
        }}
        renderInput={(params) => (
            <TextField {...params} label="Pick an item" fullWidth />
        )}

        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                onEnterPressed(((event.target) as any).value);
            }
        }}
    />;
};

export default SearchBox;
