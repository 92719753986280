import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFileMetadata, getImage, readFile, searchFileNameInFolder } from '../driveApi';
import { marked } from 'marked';

const FileDetails: React.FC = () => {
    const fileId = useParams<{ fileId: string }>().fileId;
    const [convertedHmtl, setConvertedHtml] = useState<string>('');
    const [fileMetadata, setFileMetadata] = useState<any>({});

    useEffect(() => {
        const loadFile = async () => {
            const fileContent = await readFile(fileId!);
            const fileMetadataLocal = await getFileMetadata(fileId!);
            console.log(fileMetadataLocal);
            setFileMetadata(fileMetadataLocal);
            if (fileMetadataLocal.mimeType === 'text/markdown') {
                const walkTokens = async (token: any) => {
                    const isLink = token.type === 'link';
                    const isImage = token.type === 'image';
                    if (isLink || isImage) {
                        console.log(token);
                        const isUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(token.href);
                        if (isUrl) {
                            return;
                        }
                        const fileName = token.href;
                        const decodedFileName = decodeURIComponent(fileName);

                        const files = await searchFileNameInFolder(decodedFileName);
                        if (files.length > 0) {
                            if (isLink) {
                                token.href = `/files/${files[0].id}`;
                                console.log(`New link: ${token.href}`);
                            } else if (isImage) {
                                token.href = `/files/${files[0].id}`;
                                const processedImageUrl = await getImage(files[0].id);
                                token.href = processedImageUrl;

                                const text = token.text;
                                const dimensions = text.match(/^\d+x\d+$/);
                                if (dimensions) {
                                    const [width, height] = dimensions[0].split('x');
                                    token.attrs = token.attrs || {};
                                    if (width !== "0") {
                                        token.attrs.width = width;
                                    }
                                    if (height !== "0") {
                                        token.attrs.height = height;
                                    }
                                }

                                console.log(`New image: ${token.href}`);
                            }
                        }
                    }
                };
                marked.use({ walkTokens, async: true });
                const html = await marked.parse(fileContent);
                setConvertedHtml(html);
            } else {
                setConvertedHtml(fileContent);
            }
        }

        if (fileId) {
            loadFile();
        }
    }, [fileId]);

    return (
        <div>
            <h2>File Details</h2>
            <p>Name: {fileMetadata.name}</p>
            <p>Size: {fileMetadata.size} bytes</p>
            <p>Last Modified: {fileMetadata.modifiedTime}</p>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: convertedHmtl }}></div>
        </div>
    );
};

export default FileDetails;
