import axios from 'axios';

export async function isTokenValid(accessToken: string): Promise<boolean> {
    const url = `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${accessToken}`;

    try {
        const response = await fetch(url);

        if (response.ok) {
            const tokenInfo = await response.json();
            console.log("Token is valid");
            console.log("Token info:", tokenInfo);
            return true;
        } else {
            const errorInfo = await response.json();
            console.log("Token is invalid or expired");
            console.log("Error info:", errorInfo);
            localStorage.removeItem('accessToken');
            return false;
        }
    } catch (error) {
        console.error("Error checking token validity:", error);
        localStorage.removeItem('accessToken');
        return false;
    }
}


// Files with a name containing the words "hello" and "goodbye"	-> name contains 'hello'
// Files that contain the word "hello" -> fullText contains 'hello'
export async function getLatestFiles(accessToken: String) {
    const response = await axios.get('https://www.googleapis.com/drive/v3/files', {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        params: {
            orderBy: 'modifiedTime desc',
            fields: 'files(id, name, modifiedTime, parents)',
        },
    });
    return response.data.files;
}

async function search(searchQuery: String, limit: number = 5) {
    const accessToken = localStorage.getItem('accessToken') ?? '';
    const response = await axios.get('https://www.googleapis.com/drive/v3/files', {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        params: {
            q: searchQuery,
            orderBy: 'modifiedTime desc',
            fields: 'files(id, name, modifiedTime, parents)',
            pageSize: limit,
        },
    });
    return response.data.files;
}

export async function getFilesInFolder(folderId: String) {
    return await search(`'${folderId}' in parents`);
}

export async function searchFiles(searchString: String, rootFolder: String) {
    return await search(`'${rootFolder}' in parents and name contains '${searchString}'`);
}

export async function searchMarkdownFilesByName(searchString: String, limit: number = 5) {
    return await search(`name contains '${searchString}' and mimeType = 'text/markdown'`, limit);
}

export async function searchMarkdownFilesByContent(searchString: string, limit: number = 5) {
    const convertedSearchString = escapeSpecialCharacters(searchString);
    return await search(`fullText contains '${convertedSearchString}' and mimeType = 'text/markdown'`, limit);
}

export async function searchFileNameInFolder(searchString: string) {
    return await search(`name contains '${searchString}'`);
}

export async function readFile(fileId: String): Promise<string> {
    const accessToken = localStorage.getItem('accessToken') ?? '';
    const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
    });
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.readAsText(response.data);
    });
}

export async function getFileMetadata(fileId: String) {
    const accessToken = localStorage.getItem('accessToken') ?? '';
    const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        params: {
            fields: 'id, name, size, modifiedTime, mimeType',
        },
    });
    return response.data;
}

export async function getImage(fileId: String): Promise<String> {
    // returns a URL that can be used in an <img> tag
    const accessToken = localStorage.getItem('accessToken') ?? '';
    const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
    });
    return URL.createObjectURL(response.data);
}

export async function getNestedFolders(folders: String[]) {
    const accessToken = localStorage.getItem('accessToken') ?? '';
    let parentFolderId = 'root';
    for (const folderName of folders) {
        const response = await axios.get('https://www.googleapis.com/drive/v3/files', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                q: `name = '${folderName}' and '${parentFolderId}' in parents`,
                fields: 'files(id, name, parents)',
            },
        });
        if (response.data.files.length === 0) {
            throw new Error(`Folder not found: ${folderName}`);
        }
        parentFolderId = response.data.files[0].id;
    }
    return parentFolderId;
}


function escapeSpecialCharacters(searchString: string): string {
    const specialChars = ['#', '$', '@', '&', '\\', '/', '!', '*', '(', ')', '[', ']', '{', '}', ':', ';', '"', "'", '<', '>', ',', '?', '%', ' '];
    let escapedString = '';

    for (const char of searchString) {
        if (specialChars.includes(char)) {
            escapedString += `\\${char}`;
        } else {
            escapedString += char;
        }
    }

    return escapedString;
}

export const formatName = (name: string) => name.replace(/\.md$/, '');
